import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, Fragment } from 'react';
import { setConfig } from 'react-hot-loader';

import { useTranslation } from 'react-i18next';
import {
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiNavDrawerGroup,
  EuiNavDrawer,
  EuiShowFor,
  EuiHeaderSectionItemButton,
  EuiIcon
} from '@elastic/eui';

import nav from './js/nav';
import HeaderUserMenu from './components/layout/HeaderUserMenu';
import HeaderProjectSelector from './components/layout/HeaderProjectSelector';

import Router from './components/Router';
import SwitchLanguage from './components/layout/SwitchLanguage';
import PrinterStatus from './components/layout/PrinterStatus';
import Error from './components/Error';
import { Redirect, useRedirect } from './jas/state/redirect';
import { Breadcrumbs } from './jas/state/breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getConsoleSettings } from './jas/state/consoleOptions';


import '@elastic/eui/dist/eui_theme_light.min.css';
import './App.css';
import Logo from './svg/Forensic.svg';

import store from './jas/store';


if ( process.env.NODE_ENV === 'development' ) {
  /*  const whyDidYouRender = require( '@welldone-software/why-did-you-render' );
   whyDidYouRender( React, {
     trackAllPureComponents: false,
   } ); */
}

setConfig( { disableHotRenderer: false } );



const App = () => {
  const [ navItems, setNavItems ] = useState( null );

  const consoleSettings = getConsoleSettings();

  const { t } = useTranslation();
  const addRedirect = useRedirect();
  const location = useLocation();


  useEffect( () => {
    setNavItems( nav( addRedirect, consoleSettings, t ) );
  }, [ location ] );


  const renderLogo = () => {
    return (
      <EuiHeaderLogo
        iconType={Logo}
        aria-label='Home'
      />
    );
  };

  let navDrawerRef = React.createRef();

  const renderMenuTrigger = () => {
    return (
      <EuiHeaderSectionItemButton
        aria-label='Open nav'
        onClick={() => navDrawerRef.current.toggleOpen()}>
        <EuiIcon type='apps' href='#' size='m' />
      </EuiHeaderSectionItemButton>
    );
  };

  return <Fragment> <div>

    <div className='header-fixed'>
      <EuiHeader className="">
        <EuiHeaderSection grow={false}>
          <EuiShowFor sizes={[ 'xs', 's' ]}>
            <EuiHeaderSectionItem border='right'>
              {renderMenuTrigger()}
            </EuiHeaderSectionItem>
          </EuiShowFor>
          <EuiHeaderSectionItem border='right'>
            {renderLogo()}
          </EuiHeaderSectionItem>
        </EuiHeaderSection>


        {/* <EuiHeaderLinks style={{display:'flex', alignItems: 'center', paddingLeft: '10px'}}> */}
        <Breadcrumbs />

        {/* </EuiHeaderLinks> */}

        <EuiHeaderSection side='right'>
          {/* Disabled the printer indicator temporary
        <EuiHeaderSectionItem>
         <PrinterStatus />
        </EuiHeaderSectionItem>
      */}
          <EuiHeaderSectionItem>
            <HeaderProjectSelector />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <SwitchLanguage />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <HeaderUserMenu />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
    </div>


    <EuiNavDrawer ref={navDrawerRef} showToolTips={true}>
      <EuiNavDrawerGroup listItems={navItems} />
      {/* <EuiHorizontalRule margin='none' /> */}
    </EuiNavDrawer>


    <Router />
    <Error />
    <Redirect />
  </div>
  </Fragment>;
};

export default hot( App );
