const schema = {
  settings: {
    updateQueryParameter: true,
    parseQueryParameter: true
  },
  fields: [
    {
      name: 'primary_parent',
      type: 'comboBox',
      i18nLabel: 'ENTITY_PRIMARY_PARENT',
      i18nHelp: 'ENTITY_PRIMARY_PARENT_HELP',
      i18nPlaceHolder: 'ENTITY_PRIMARY_PARENT',
      operator: 'inv2',
      listSettings: {
        allowExclusions: true,
        class: 'noFlexWrap',
        type: 'remote',
        singleSelection: true,
        query: {
          kind: 'entityV2',
          project_id: 'oYsfCBg3ek44zbru0',
          orderBy: 'payload.bsLevel desc',
          operator: 'and',
          //collapse: 'payload.entity',
          query: [
            {
              field: 'status',
              operator: 'IN',
              value: [
                'active',
                'private',
                'unlisted'
              ]
            },
            {
              field: 'payload.name',
              operator: 'multiBf*',
              value: '{{value}}'
            },
          ]
        }
      },
      showOnMobile: true
    }
  ]
};

export { schema as default };
