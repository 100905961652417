import React, { useState, Fragment } from 'react';
import { EuiSpacer, EuiButtonGroup } from '@elastic/eui';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CreateTransitForm from './Form';
import API from '../../../../../js/api';
import { arabicEncoding } from '../../../../../js/functions';
import PdfScanner from '../../PdfScanner';
import { useError } from '../../../../../components/Error';

const CreatePassenger = ( { onSubmit, formFields } ) => {
  const [ scanType, setScanType ] = useState( '01' );
  const [ scannedResults, setScannedResults ] = useState( null );
  const [ formErrors, setFormErrors ] = useState( null );
  const [ openCamera, setOpenCamera ] = useState( false );

  const { t } = useTranslation();
  const addError = useError();

  const toggleScanType = [
    {
      id: '01',
      label: t( 'Scan Barcode' )
    },
    {
      id: '02',
      label: t( 'Scan passport' ),
      isDisabled: true
    }
  ];

  const handleScan = data => {
    console.log( 'after scan data', data );
    setOpenCamera( false );
    if ( data.length > 5 ) {
      setScannedResults( data );
    } else {
      addError( {
        title: 'The barcode scanned is not supported.',
        color: 'warning',
        iconType: 'help'
      } );
    }
  };

  const submitForm = async form => {
    try {
      form.background_check = form.background_check.toString();
      form.history_check = form.history_check.toString();
      form.auto_fees = form.auto_fees.toString();
      const res = await API.createObject(
        {
          reference: '$.name',
          project_id: 'o3WWDQHpek5dtbode',
          kind: 'borderEXCSEntry',
          payload: form,
          classification: 'confidential',
          source: 'https://standards.stratint.uk/sources/manual',
          status: 'draft',
          createdAt: new Date().toISOString(),
          expiredAt: moment()
            .add( form.entry_length, 'days' )
            .toISOString()
        },
        {
          instant: true
        }
      );
      onSubmit( res.data, form );
    } catch ( er ) {
      console.log( 'error: ', er );
      addError( {
        title: er.code,
        text: er.message,
        color: 'danger',
        iconType: 'crossInACircleFilled'
      } );
      if ( er.code === 409 ) {
        setFormErrors( er.error );
      }
    }
  };

  const onChange = optionId => {
    setScanType( optionId );
  };

  return (
    <Fragment>
      <EuiButtonGroup
        legend='Type'
        options={toggleScanType}
        idSelected={scanType}
        onChange={onChange}
      />
      <EuiSpacer />
      <PdfScanner
        resultsDecoder={arabicEncoding}
        onScan={handleScan}
        {...{ openCamera, setScannedResults, setOpenCamera }}
      />
      <CreateTransitForm
        scannedResults={scannedResults}
        submitForm={submitForm}
        formFields={formFields}
        formErrors={formErrors}
      />
    </Fragment>
  );
};

export default CreatePassenger;
