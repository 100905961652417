
import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';/*  */
import { EuiLoadingSpinner, EuiEmptyPrompt } from '@elastic/eui';
import _ from 'lodash';
import { Translation } from 'react-i18next';



class GenericLoading extends Component
{
  _isMounted = false;
  parsedd;

  constructor ()
  {
    // @ts-ignore
    super();
    this.state = {
      resp: ( <EuiLoadingSpinner size='s' /> )
    };

  }

  async componentDidMount ()
  {
    let res;
    this._isMounted = true;
    if ( this._isMounted )
    {

      res = <Translation>
        {
          ( t, { i18n } ) =>
            <EuiEmptyPrompt
              title={<h2>{t( this.props.title || 'GENERIC_LOADING' )}</h2>}
              body={
                <Fragment>
                  <p>
                    <EuiLoadingSpinner size="xl" />
                  </p>
                  <p>{t( this.props.message || 'GENERIC_LOADING_MESSSAGE' )}</p>
                </Fragment>

              }
            />
        }
      </Translation>;

      this.setState( { resp: res } );
    }
  }

  //shouldComponentUpdate (nextProps, nextState) {
  //  this.parsed = (nextProps.data != this.props.data) || nextState.firstRun != 0;
  //}

  render ( {
    title,
    messsage
  } = this.props )
  {

    return (
      this.state.resp
    );
  }
}

export { GenericLoading as default, GenericLoading };
