import React, { useEffect, useLayoutEffect, useState, Fragment } from 'react';
import {
  EuiBasicTable,
  EuiLink,
  EuiIcon,
  EuiLoadingSpinner,
  EuiEmptyPrompt,
  EuiButton
} from '@elastic/eui';
import {
  useRouteMatch,
  withRouter,
  useParams,
  useLocation,
  Switch,
  Route
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import objectHash from 'object-hash';

import API from '../../js/api';

import { handleScroll } from '../../js/functions';

import { useError } from '../Error';
import { useRedirect, useQuery, GetQueryParameter } from '../../jas/state/redirect';
import { queryParseReplace } from '../../jas/state/consoleOptions';


import IListEdit from '../ui/IlistEdit';

import { FieldParser, GenericLoading } from './helpers';
import IDescribeObject from './IDescribeObj';
import IArchiveObj from './IArchiveObj';
import { ResourceStore } from 'i18next';
import { HeaderProgress } from './IHeaderProgress';


const selectQueryFields = async ( schema ) => {
  let selectedFields = [];
  if ( schema?.settings?.queryFields ) {
    selectedFields = schema.settings.queryFields;
  }

  for ( const field of schema.fields ) {
    selectedFields.push( field.data );
  }

  return selectedFields.join( ',' );
};


const IListObjects = ( { query, data, listSchema, filters, forceReload, describeSize, isLoading, sort, describeSchema } ) => {
  //{ query: {}, data?: [], listSchema: any, describeSchema: {}, forceReload: Function, describeSize: 's' | 'm' | 'l' | 'xl'; filters }
  const [ items, setItems ] = useState( [] );
  const [ id, setId ] = useState( null );
  const [ metaQuery, setMetaQuery ] = useState( {} );
  const [ page, setPage ] = useState( '' );
  const [ nextPage, setNextPage ] = useState( '' );

  const [ loading, setLoading ] = useState( false );
  const [ tableLoading, setTableLoading ] = useState( false );
  const [ edit, setEdit ] = useState( null );
  const [ reload, setReload ] = useState( true );
  const [ archive, setArchive ] = useState( null );
  const [ sortField, setSortField ] = useState( 'createdAt' );
  const [ sortDir, setSortDir ] = useState( 'desc' );
  const [ queryDetails, setQueryDetails ] = useState( query );
  const [ filtersQuery, setFiltersQuery ] = useState( filters );
  const [ previousFiltersQuery, setPreviousFiltersQuery ] = useState( [] );

  const [ orderBy, setOrderBy ] = useState( null );
  const [ previousOrderBy, setPreviousOrderBy ] = useState( [] );

  const [ running, setRunning ] = useState( false );


  const { url } = useRouteMatch();
  const addError = useError();
  const addRedirect = useRedirect();
  const manageQuery = useQuery();
  const getUrlQuery = GetQueryParameter();


  const { t } = useTranslation();

  //Disppatch Filter change
  useEffect( () => {
    console.log( 'FILTER CHAGE RETREIVED' );
    if ( filters ) {
      console.log( 'FILTER CHAGE RETREIVED 2' );

      setFiltersQuery( filters );
      setReload( true );
    }
  }, [ objectHash( filters || [], { unorderedArrays: true } ) ] );

  useEffect( () => {

    ( async () => {
      try {

        if ( !reload || running ) {
          return;
        }
        //console.log( 'IListObjects Execute Primary', filters, 'page', page );
        //setItems( [] );
        if ( data ) {
          setItems( data );
          setLoading( false );
        } else {
          setRunning( true );

          if ( !page ) {
            setLoading( true );
          }
          //Get the fields required for the query based on the schema
          const fields = await selectQueryFields( listSchema );
          //console.log( 'IListObjects Execute Primary', 2, filters, 'page', page );

          //Checck if URL query is set
          if ( orderBy && objectHash( orderBy, { unorderedArrays: true } ) !== objectHash( previousOrderBy, { unorderedArrays: true } ) ) {
            setQueryDetails( _.set( queryDetails, 'orderBy', orderBy ) );
            setPreviousOrderBy( objectHash( orderBy, { unorderedArrays: true } ) );
            setPage( null );
            setItems( [] );
          }

          if ( filtersQuery?.length > 0 ) {
            if ( objectHash( filtersQuery, { unorderedArrays: true } ) !== objectHash( previousFiltersQuery, { unorderedArrays: true } ) ) {
              setLoading( true );
              setItems( [] );
              setNextPage( null );
            }
            setPreviousFiltersQuery( { ...filtersQuery } );

            queryDetails.query = [
              ...filtersQuery
            ];
            setQueryDetails( queryDetails );
          }
          //console.log( 'filtersQuery', objectHash( filtersQuery, { unorderedArrays: true } ), 'previousFiltersQuery', objectHash( previousFiltersQuery, { unorderedArrays: true } ) );

          //console.log( 'query', query );

          //Parse Replace query and run it
          const res = await API.queryObject( queryParseReplace( queryDetails ),
            {
              pageToken: page,
              skipCache: 'true',
              maxResults: 100,
              fields: fields
            } );


          setItems( res.data );
          setLoading( false );
          if ( filtersQuery?.length > 0 && objectHash( filtersQuery, { unorderedArrays: true } ) !== objectHash( previousFiltersQuery, { unorderedArrays: true } ) ) {

            setLoading( true );
            setItems( res.data );
          } else {
            setItems( [ ...items, ...res.data ] );

          }


          //console.log( 'page', nextPage, 'store next page token', res.metadata.nextPageToken );
          if ( nextPage != res.metadata.nextPageToken ) {
            setNextPage( res.metadata.nextPageToken );
          }

          let orderBySplit = res.metadata.orderBy.split( ' ' );

          if ( query.orderBy ) {
            orderBySplit = query.orderBy.split( ' ' );
          }

          setLoading( false );
          setTableLoading( false );
          setRunning( false );
          setSortField( orderBySplit[ 0 ] );
          setSortDir( orderBySplit[ 1 ] );

        }
      } catch ( er ) {
        setLoading( false );

        addError( {
          title: t( 'ILISTOBJECTS_ERROR' ),
          text: er.message,
          color: 'danger',
          iconType: 'help'
        } );
      }

      if ( reload ) {
        setReload( false );
      }
    } )();
  }, [ reload, page ] );

  //Dispatch Force Reload
  useEffect( () => {
    if ( forceReload ) {
      setReload( true );
      setLoading( true );
      setItems( [] );
      forceReload = false;
    }
  }, [ forceReload ] );

  const onScroll = () => {
    // console.log( 'page', page, 'nextPage', nextPage );

    if ( !tableLoading && page != nextPage ) {
      // console.log( 'metaQuery', 'onScroll', 2, 'running', running, 'page', page, 'nextPage', nextPage, 2 );
      setPage( nextPage );
      setTableLoading( true );
      setReload( true );
    }
  };

  //const onScrollThrottled = _.throttle( onScroll, 1000, { leading: false, trailing: true } );

  useEffect( () => {
    //console.log( 'metaQuery', 'enabled', 1 );
    if ( nextPage ) {
      //console.log( 'metaQuery', 'enabled', 2 );
      document.addEventListener( 'scroll', handleScroll( onScroll ) );
      return () =>
        document.removeEventListener( 'scroll', handleScroll( onScroll ) );
    }
  }, [ nextPage ] );

  const handleEdit = ( e, item ) => {
    e.stopPropagation();
    setEdit( item.id );
  };

  const handleArchive = ( e, item ) => {
    e.stopPropagation();
    setArchive( item );

  };

  const actions = {
    edit: ( item ) => {
      return (
        <EuiLink color='secondary' onClick={( e ) => { handleEdit( e, item ); }}>
          <EuiIcon size='s' title={t( 'EDIT' )} type='pencil' />
        </EuiLink >
      );
    },
    archive: ( item ) => {
      return (
        <EuiLink color='secondary' onClick={( e ) => { handleArchive( e, item ); }}>
          <EuiIcon size='s' title={t( 'ARCHIVE' )} type='exit' />
        </EuiLink >
      );
    }
  };

  const mapSchemaForTable = ( fields ) => {

    return fields.map( f => ( {
      field: f.name || f.data,
      name: t( f.i18nTitle ),
      description: t( f.i18nDescription ),
      sortable: f.sortable || false,
      width: f.width,
      align: f.align || 'left',
      truncateText: f.truncateText || true,
      textOnly: f.truncateText || false,
      render: ( _, item ) => <FieldParser field={f} data={item} />
    } ) );
  };

  const columns = [
    ...mapSchemaForTable( listSchema.fields ),
  ];

  if ( _.isArray( listSchema.settings.actions ) ) {
    columns.push( {
      name: t( 'GENERIC_TABLE_ACTIONS' ),
      actions: listSchema.settings.actions.map( a => ( {
        render: actions[ a ]
      } ) )
    } );
  }

  // in progress will be configured using schema.settings.rowClick
  // rowClick[optional] will contain 'flyout' or 'modal' as value

  let rowProps;

  if ( listSchema.settings.row.click ) {
    rowProps = item => {
      const { id } = item;
      return {
        'data-objectId': id,
        className: 'customRowClass',
        onClick: () => { addRedirect( id ); }
      };
    };
  }

  const closeView = () => {
    setId( null );
    addRedirect( './' );
    setEdit( null );
    setArchive( null );
  };

  const closeArchive = ( id ) => {
    setItems( [] );
    _.remove( items, ( item ) => { return item.id == id; } );
    setItems( items );
    closeView();
  };

  const displayReloadContent = listSchema.settings.refreshButton !== undefined ? listSchema.settings.refreshButton : false;
  const reloadContent = () => {
    setReload( true );
  };

  const onTableChange = ( props ) => {
    if ( props.sort ) {
      let orderBy = `${ props.sort.field } ${ props.sort.direction }`;
      setOrderBy( orderBy );
      manageQuery( 'set', 'orderBy', orderBy );
      setReload( true );
    };
  };

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDir,
    }
  };

  let Res = <></>;
  let RefreshButton = <></>;
  let Table = <></>;
  let RowClick = <></>;
  let ItemRedirect = <></>;
  if ( displayReloadContent ) {
    RefreshButton = <EuiButton iconType='refresh'
      size='s' onClick={() => reloadContent()}>
      {t( 'GENERIC_RELOAD' )}
    </EuiButton>;
  }

  Table = <EuiBasicTable
    items={items}
    responsive={true}
    columns={columns}
    rowProps={rowProps}
    loading={tableLoading}
    sorting={sorting}
    onChange={onTableChange}
    noItemsMessage={t( 'GENERIC_NO_DATA' )}
  />;

  if ( listSchema.settings.row.click ) {
    RowClick = <IDescribeObject
      schema={describeSchema}
      id={id}
      close={closeView}
      type={listSchema.settings.row.click.view}
      size={describeSize}
    />;
  }


  const UrlParameter = ( props ) => {
    let urlParamsId = useParams()[ 'id' ];
    if ( urlParamsId ) {
      setId( urlParamsId );
      return RowClick;
    } else {
      setId( null );
    }
    return <></>;;
  };


  if ( loading ) {
    return ( <GenericLoading /> );
  } else {
    return (
      <>
        <HeaderProgress isLoading={tableLoading} />
        <Fragment>
          {RefreshButton}
          {Table}
          <IArchiveObj id={archive?.id} data={archive} onClose={closeArchive} />
          <IListEdit id={edit} close={closeView} />
        </Fragment>
        <Fragment>
          {ItemRedirect}
          <Switch>
            <Route path={`${ url }/:id`} children={<UrlParameter />} />
          </Switch>
        </Fragment>
      </>
    );
  }
};

const IListObjectsWithRouter = withRouter( IListObjects );

export { IListObjectsWithRouter as default, IListObjects as IListObjects };
