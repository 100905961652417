import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EuiGlobalToastList } from '@elastic/eui';
import { removeError, addError } from '../js/actions/errors';

export const useError = () => {
  const dispatch = useDispatch();
  return er => dispatch(addError(er));
}

const Error = () => {
  // error = {title, text, color, iconType, toastLifeTimeMs}
  const dispatch = useDispatch();
  const errors = useSelector(state => state.errors);

  return (
    <EuiGlobalToastList
      toasts={errors}
      dismissToast={er => dispatch(removeError(er))}
      toastLifeTimeMs={6000}
    />
  );
};

export default Error;
