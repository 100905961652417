export default {
  settings: {
    textStyle: 'reverse',

    title: ' ',
    i18nTitleSuffix: 'POST_DETAILS',
    actions: [
    ]
  },
  fields: [
    {
      field: 'id',

      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      type: 'text'
    },
    {
      field: 'reference',
      data: '_reference',
      i18nTitle: 'REFERENCE',
      type: 'text'
    },
    {
      data: 'payload.type',
      i18nTitle: 'TYPE',
      sortable: true,
      type: 'text'
    },
    {
      field: 'parent',

      data: 'primary_parent',
      i18nTitle: 'PRIMARY_PARENT',
      type: 'b3ObjectField',
      display: {
        field: 'id',
        data: [ '/', 'id' ],
        type: 'link',
        label: 'payload.name'
      }
    },
    {
      data: 'payload',
      i18nTitle: 'PAYLOAD',
      type: 'jsonViewer',
      jsonViewerSettings: {
        collapsed: false
      }
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      type: 'status'
    }
  ]
};
