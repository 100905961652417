const schema = {
  settings: {
    i18nTitle: 'ENTITIES_CREATE',
    refreshOnCreate: true,
    showOnCreate: false,
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'entityV2',
      reference: '$.name',
      defaultClassification: 'unclassified',
      status: 'active',
      project_id: 'oYsfCBg3ek44zbru0'
    }
  },
  fields: [
    {
      name: 'payload.name',
      type: 'text',
      i18nLabel: 'NAME',
      i18nHelp: 'ENTITIES_NAME_HELP',
      i18nPlaceHolder: 'ENTITIES_NAME_PLACEHOLDER',
      default: '',
      showOnMobile: true,
      validation: {
        presence: true
      }
    },
    {
      name: 'primary_parent',
      type: 'comboBox',
      i18nLabel: 'ENTITY_PRIMARY_PARENT',
      i18nHelp: 'ENTITY_PRIMARY_PARENT_HELP',
      i18nPlaceHolder: 'ENTITY_PRIMARY_PARENT',
      operator: 'inv2',
      listSettings: {
        allowExclusions: true,
        class: 'noFlexWrap',
        type: 'remote',
        singleSelection: true,
        query: {
          kind: 'entityV2',
          project_id: '{{CONSOLE_SELECTED_PROJECT}}',
          orderBy: 'payload.bsLevel desc',
          operator: 'and',
          //collapse: 'payload.entity',
          query: [
            {
              field: 'status',
              operator: 'IN',
              value: [
                'active',
                'private',
                'unlisted'
              ]
            },
            {
              field: 'payload.name',
              operator: 'multiBf*',
              value: '{{value}}'
            },
          ]
        }
      },
      showOnMobile: true
    },
    {
      name: 'payload.type',
      type: 'select',
      i18nLabel: 'ENTITY_TYPE',
      i18nHelp: 'ENTITY_TYPE_HELP',
      i18nPlaceHolder: 'ENTITY_TYPE',
      default: 'location',
      listSettings: {
        options: [
          {
            i18nTitle: 'fbPage',
            value: 'fbPage'
          },
          {
            i18nTitle: 'location',
            value: 'location'
          },
          {
            i18nTitle: 'contentCategory',
            value: 'contentCategory'
          },
          {
            i18nTitle: 'TAG',
            value: 'tag'
          }
        ]
      },
      showOnMobile: true,
      validation: {
        presence: true
      }
    },
    {
      name: 'payload.tmpOptions.searchTw',
      type: 'switch',
      i18nLabel: 'SEARCH_TWITTER',
      i18nHelp: 'SEARCH_TWITTER_HELP',
      i18nPlaceHolder: 'SEARCH_TWITTER_PLACEHOLDER',
      default: true,
      showOnMobile: true
    },
    {
      name: 'payload.tmpOptions.searchFb',
      type: 'switch',
      i18nLabel: 'SEARCH_FACEBOOK',
      i18nHelp: 'SEARCH_FACEBOOK_HELP',
      i18nPlaceHolder: 'SEARCH_FACEBOOK_PLACEHOLDER',
      default: true,
      showOnMobile: true
    },
  ]
};

export { schema as default };
