const schema = {
  settings: {
    maxDisplayLimit: 1000,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
      click: {
        type: 'none',
        view: 'flyout',
        schema: 'none',
        featureRequired: 'none'
      }
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    action: {
    },
    queryFields: [ 'payload.description' ]
  },
  query: {
    kind: 'socialVideo',
    project_id: 'oYsfCBg3ek44zbru0',
    orderBy: 'createdAt desc',
    operator: 'and',
    maxResults: 50,
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      field: 'image',
      data: 'payload.thumbnailKEY',
      i18nTitle: 'THUMBNAIL',
      sortable: false,
      type: 'image',
      showOnMobile: true,
      size: 's',
      alt: '',
      hasShadow: true
    },
    {
      field: 'reference',
      data: '_reference',
      i18nTitle: 'GENERIC_DESCRIPTION',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true
    },
    {
      data: 'payload.length',
      i18nTitle: 'VIDEO_LENGTH',
      sortable: true,
      type: 'length',
      showOnMobile: true
    },
    {
      data: 'payload.source',
      i18nTitle: 'SOURCE_PLATFORM',
      sortable: false,
      type: 'text',
      showOnMobile: true
    },
    /*  {
       field: 'publisher',

       data: 'primary_parent',
       i18nTitle: 'PUBLISHER',
       sortable: false,
       showOnMobile: true,
       type: 'b3ObjectField',

       display: {
         field: 'id',
         data: [ 'https://facebook.com/', 'payload.id' ],
         type: 'link',
         label: 'payload.name'
       }
     }, */
    {
      //field: 'likes',

      data: 'payload.metrics.likes',
      i18nTitle: 'LIKES_COUNT',
      sortable: true,
      type: 'numberRound',
      showOnMobile: true
    },
    {
      //field: 'comments',

      data: 'payload.metrics.comments',
      i18nTitle: 'COMMENTS_COUNT',
      sortable: true,
      type: 'numberRound',
      showOnMobile: true
    },
    {
      // field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      relative: true
      //format: 'MMMM Do YYYY, h:mm:ss a'
    }
  ]
};

export { schema as default };
