import React, { Fragment } from 'react';

import {
  EuiTitle,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentBody,
  EuiSpacer
} from '@elastic/eui';



const PageLayout = ( props ) => {


  let headerArea;
  let headerArea1;
  let headerArea3;
  let headerArea4;
  let headerArea5;
  let headerArea2;

  if ( props.title ) {
    headerArea1 = <EuiPageHeaderSection>
      <EuiTitle size='l'>
        <h2>{props.title}</h2>
      </EuiTitle>
      <p>{props.subTitle}</p>
    </EuiPageHeaderSection>;
  } else if ( props.headerLeft ) {
    headerArea1 = <EuiPageHeaderSection>{props.headerLeft}</EuiPageHeaderSection>;
  };

  if ( props.header ) {
    headerArea = <> {props.header} <EuiSpacer size={'s'} /></>;

  }

  if ( props.headerRight ) {
    headerArea2 = <EuiPageHeaderSection>{props.headerRight}</EuiPageHeaderSection>;
  }

  if ( headerArea1 || headerArea2 ) {
    headerArea = <EuiPageHeader>
      {headerArea1}
      {headerArea2}
    </EuiPageHeader>;
  }

  return (
    <EuiPage className='euiNavDrawerPage'>
      <EuiPageBody className='euiNavDrawerPage__pageBody'>
        {headerArea}
        <EuiPageContent>
          <EuiPageContentBody>
            {props.children}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default PageLayout;
